@import url('https://fonts.googleapis.com/css2?family=Risque&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@500&family=Poppins&display=swap');

.game-font
{
  font-family: 'Risque', cursive;
  color: #F8EDE4;
}
.trainer-font
{
  font-family: 'Risque', cursive;
  color: #404EED;
}
.bg-img
{
  background-image: url('./assets/gameBG.png');
  min-height: 100vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: -moz-hidden-unscrollable;
  overflow-y: hidden;
 
}




.game-border
{
  border: 2px solid #EF9910;
  
}

.game-headers-bg
{
  
  background: #f79c0b;
  box-shadow: 0px 2.07958px 20.79585px 0px rgba(81, 80, 80, 0.2), 0px 8.31834px 10.39792px 0px rgba(40, 39, 39, 0.12), 0px 4.15917px 8.31834px 0px rgba(110, 110, 110, 0.14);
}
.game-headers-bg2
{
  background: radial-gradient(50% 50% at 50% 50%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);

mix-blend-mode: overlay;

}
.text-shad
{
  color: #F8EDE4;
  text-shadow: 0px 2.0795845985412598px 4.1591691970825195px rgba(0, 0, 0, 0.20), 0px 2.0795845985412598px 2.0795845985412598px rgba(0, 0, 0, 0.22), 0px 2.0795845985412598px 4.1591691970825195px rgba(0, 0, 0, 0.24), 2.0795845985412598px 0px 6.238753795623779px rgba(0, 0, 0, 0.35);
}

.pin-box
{
  background: radial-gradient(50% 50% at 50% 50%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);

  mix-blend-mode: overlay;
  
}
.box
{
  
  background-color: #312323;


}
.line-style
{
  border-radius: 325px;
  opacity: 0.5;
  background: radial-gradient(50% 50% at 50% 50%, #FFF 0%, rgba(255, 255, 255, 0.00) 100%);
  mix-blend-mode: overlay;
  box-shadow: white;
  box-shadow: 0 20px 25px -5px;
}

.number-style
{
  background: linear-gradient(180deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%), #A55409;
background-blend-mode: overlay, normal;
box-shadow: 0px 2.07958px 20.79585px 0px rgba(0, 0, 0, 0.20), 0px 8.31834px 10.39792px 0px rgba(0, 0, 0, 0.12), 0px 4.15917px 8.31834px 0px rgba(0, 0, 0, 0.14);

}


.scrollbar::-webkit-scrollbar {
  width: 6px; 
}


.scrollbar::-webkit-scrollbar-track {
  background: #5b5b5b; /* Background color of the scrollbar track */
  border-radius: 5px;
  margin-top: 2px;
  margin-bottom: 2px;
}

.scrollbar::-webkit-scrollbar-thumb {
  
  background: #FFCD14; 
  border-radius: 10px; 
}


/* trainer */

.trainer-stats-font,
.admin-font
{
  font-family: 'Poppins', sans-serif;
}

#path1, #path2 /* Add more selectors for other paths if needed */ {
  stroke: #818181; /* Default color */
  stroke-width: 1.5;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: stroke 0.3s ease; /* Add a smooth transition */
}


.trainer-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.trainer-scrollbar::-webkit-scrollbar-track {
  background: #bdbdbd; /* Background color of the scrollbar track */
}

.trainer-scrollbar::-webkit-scrollbar-thumb {
  background: #404EED; /* Change this color to your desired scrollbar color */
  border-radius: 10px;
}


.admin-scrollbar::-webkit-scrollbar {
  width: 6px;
}

.admin-scrollbar::-webkit-scrollbar-track {
  background: #bdbdbd; /* Background color of the scrollbar track */
}

.admin-scrollbar::-webkit-scrollbar-thumb {
  background: #0E9F6E; /* Change this color to your desired scrollbar color */
  border-radius: 10px;
}

.thanksModal
{



  background-image: linear-gradient(to bottom,#f2f2f2,#fbf3ad ,#fef079);
  background-clip: text;
  /* Set text fill color to transparent so the gradient shows through */
  -webkit-background-clip: text;
  
  color: transparent;
  /* rgba(255, 243, 137, 1) */
  text-shadow: 2px 2px 4px rgba(70, 65, 15, 0.5);
  
}

.legals
{
  font-family: 'Poppins', sans-serif;
}